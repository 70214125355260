export default {
    install(Vue) {
        Vue.prototype.$getSelectedMonthYear = function () {
            const month = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
            const date = new Date()

            let bulan = localStorage.getItem("bulan")
            let tahun = localStorage.getItem("tahun")

            if(!bulan){
                bulan = date.getMonth()
                localStorage.setItem("bulan", bulan)
            }

            if(!tahun){
                tahun = date.getFullYear()
                localStorage.setItem("tahun", tahun)
            }

            let stringify = month[bulan-1] + " " + tahun
            let dateTime = `${tahun}-${bulan.toString().padStart(2, '0')}-01`

            let bulanPrev = bulan == 1 ? 1 : bulan - 1;
            let dateTimePrev = `${tahun}-${bulanPrev.toString().padStart(2, '0')}-01`
            let yearMonth = `${tahun}-${bulan.toString().padStart(2, '0')}`

            return {
                stringify,
                dateTime,
                dateTimePrev,
                yearMonth
            }
        };
        Vue.prototype.$getIndonesiaMonth = function (date) {
            const month = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
            let split = date.split("-")

            let stringify = month[split[1]-1].toUpperCase() + " " + split[0]

            return {
                stringify,
            }
        };
        Vue.prototype.$moneyToFloat = function (input) {
            input = input.toString()
            input = input.replaceAll(".", "")
            input = input.replaceAll(",", ".")

            return parseFloat(input)
        };
        Vue.prototype.$moneyFormat = function (input, init = true, allowZero = true) {
            if (!input && input != "0") return "0"
            let inputString = input.toString()

            if (init) {
                inputString = inputString.replaceAll(".", ",")
            } else {
                if (allowZero) {
                    inputString = inputString.replace(/[^,\d]/g, '').toString()
                } else {
                    inputString = inputString.replace(/[^,\d]/g, '').replace(/^0+/, '').toString()
                }
            }

            let split = inputString.split(",")
            let sisa = split[0].length % 3
            let rupiah = split[0].substr(0, sisa)
            let ribuan = split[0].substr(sisa).match(/\d{3}/gi)

            if (ribuan) {
                let separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }

            rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;

            if (parseInt(input) < 0) {
                return "-" + rupiah
            }

            return rupiah
        };
        Vue.prototype.$processMoney = function (input) {
            if(!input) return "0"
            
            if(typeof input == "number"){
                input = input.toString()
            }
            input = input.replaceAll(".", "")
            input = input.replaceAll(",", ".")

            return input
        }

        Vue.prototype.$moneyFormatReport = function (input, init = true, allowZero = true) {
            if (!input && input !== 0) return "0";
        
            let inputString = input.toString();
        
            if (init) {
                // Hilangkan semua karakter selain digit dan koma
                inputString = inputString.replace(/[^0-9,]/g, "");
            } else {
                if (!allowZero) {
                    // Hilangkan nol di awal string
                    inputString = inputString.replace(/^0+/, "");
                }
                // Hilangkan semua karakter selain digit dan koma
                inputString = inputString.replace(/[^0-9,]/g, "");
            }
        
            // Pisahkan angka desimal dan angka ribuan
            let parts = inputString.split(",");
            let rupiah = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        
            if (parts.length > 1) {
                rupiah += "," + parts[1].padEnd(2, "0");
            } else {
                rupiah += ",00";
            }
        
            if (parseFloat(input) < 0) {
                return "-" + rupiah;
            }
        
            return rupiah;
        };

        Vue.prototype.$moneyFormatReportInvert = function (input, init = true, allowZero = true) {
            if (!input && input !== 0) return "0";
        
            let inputString = input.toString();
        
            if (init) {
                // Hilangkan semua karakter selain digit dan koma
                inputString = inputString.replace(/[^0-9,]/g, "");
            } else {
                if (!allowZero) {
                    // Hilangkan nol di awal string
                    inputString = inputString.replace(/^0+/, "");
                }
                // Hilangkan semua karakter selain digit dan koma
                inputString = inputString.replace(/[^0-9,]/g, "");
            }
        
            // Pisahkan angka desimal dan angka ribuan
            let parts = inputString.split(",");
            let rupiah = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        
            if (parts.length > 1) {
                rupiah += "," + parts[1].padEnd(2, "0");
            } else {
                rupiah += ",00";
            }
        
            if (parseFloat(input) > 0) {
                return "-" + rupiah;
            }
        
            return rupiah;
        };
    },
};