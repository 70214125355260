import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/home/Home.vue'),
    meta: {
      auth: true
    }
  },
  /** barang */
  {
    path: '/file/barang',
    name: 'file-barang',
    component: () => import('../views/file/barang/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/barang/create',
    name: 'file-file-barang-create',
    component: () => import('../views/file/barang/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/barang/update/:id',
    name: 'file-file-barang-update',
    component: () => import('../views/file/barang/Update.vue'),
    meta: {
      auth: true
    }
  },
  /** Supplier */
  {
    path: '/file/supplier',
    name: 'file-supplier',
    component: () => import('../views/file/supplier/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/supplier/create',
    name: 'file-file-supplier-create',
    component: () => import('../views/file/supplier/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/supplier/update/:id',
    name: 'file-file-supplier-update',
    component: () => import('../views/file/supplier/Update.vue'),
    meta: {
      auth: true
    }
  },
  /** pelanggan */
  {
    path: '/file/pelanggan',
    name: 'file-pelanggan',
    component: () => import('../views/file/pelanggan/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/pelanggan/create',
    name: 'file-file-pelanggan-create',
    component: () => import('../views/file/pelanggan/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/pelanggan/update/:id',
    name: 'file-file-pelanggan-update',
    component: () => import('../views/file/pelanggan/Update.vue'),
    meta: {
      auth: true
    }
  },
  /** kode-perkiraan */
  {
    path: '/file/kode-perkiraan',
    name: 'file-kode-perkiraan',
    component: () => import('../views/file/kode-perkiraan/Index.vue'),
    meta: {
      auth: true
    }
  },
  /** jenis barang */
  {
    path: '/file/jenis-barang',
    name: 'jenis-barang',
    component: () => import('../views/file/jenis-barang/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/jenis-barang/create',
    name: 'jenis-barang-create',
    component: () => import('../views/file/jenis-barang/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/file/jenis-barang/update/:id',
    name: 'jenis-barang-update',
    component: () => import('../views/file/jenis-barang/Update.vue'),
    meta: {
      auth: true
    }
  },
  // Pembelian
  {
    path: '/pembelian',
    name: 'Pembelian',
    component: () => import( /* webpackChunkName: "store" */ '../views/pembelian/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/pembelian/create',
    name: 'PembelianCreate',
    component: () => import( /* webpackChunkName: "store" */ '../views/pembelian/Create.vue'),
    meta: {
      auth: true
    }
  },
  // Pembelian
  {
    path: '/penjualan',
    name: 'Penjualan',
    component: () => import( /* webpackChunkName: "store" */ '../views/penjualan/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/penjualan/create',
    name: 'PenjualanCreate',
    component: () => import( /* webpackChunkName: "store" */ '../views/penjualan/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/transaksiJurnal',
    name: 'TransaksiJurnal',
    component: () => import( /* webpackChunkName: "store" */ '../views/transaksiJurnal/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/transaksiJurnal/create',
    name: 'TransaksiJurnalCreate',
    component: () => import( /* webpackChunkName: "store" */ '../views/transaksiJurnal/Create.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/transaksiJurnal/update/:id',
    name: 'TransaksiJurnalUpdate',
    component: () => import( /* webpackChunkName: "store" */ '../views/transaksiJurnal/Update.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock',
    name: 'LaporanStock',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/Stock.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/gl',
    name: 'LaporanGL',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/GL.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/gl/subBukuBesar',
    name: 'SubBukuBesar',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/SubBukuBesarMonth.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/gl/neracaSaldo',
    name: 'NeracaSaldo',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/NeracaSaldo.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/gl/neraca',
    name: 'NeracaSaldo',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/Neraca.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/gl/rugiLaba',
    name: 'RugiLaba',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/RugiLaba.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/barang',
    name: 'LaporanStockBarang',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/Barang.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/supplier',
    name: 'LaporanStockSupplier',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/Supplier.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/pelanggan',
    name: 'LaporanStockPelanggan',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/Pelanggan.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/pembelian',
    name: 'LaporanStockPembelian',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/Pembelian.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/transaksi-pembelian',
    name: 'LaporanStockTrxPembelian',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/TransaksiPembelian.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/penjualan',
    name: 'LaporanStockPenjualan',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/Penjualan.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/stock/transaksi-penjualan',
    name: 'LaporanStockTrxPenjualan',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/TransaksiPenjualan.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/laporan/gl/kodePerkiraan',
    name: 'LaporanGLKodePerkiraan',
    component: () => import( /* webpackChunkName: "store" */ '../views/laporan/result/KodePerkiraan.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/LoginView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/auth/RegisterView.vue'),
    meta: {
      auth: false
    }
  },
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // if (!to.matched.some(record => record.meta.auth) && store.state.auth.isLoggedIn) {
  //   next("/")
  // } else if (to.matched.some(record => record.meta.auth) && !store.state.auth.isLoggedIn) {
  //   router.push("/login")
  // } else {
  //   next()
  // }
  next()
});


export default router