import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueToast from 'vue-toast-notification';
import {
  setHeaderToken
} from '../utils/auth'
import 'vue-toast-notification/dist/theme-default.css';

import moment from 'moment'
import 'moment/locale/id'
moment.locale('id')

Vue.config.productionTip = false
Vue.use(VueToast);

const token = localStorage.getItem('token');

if (token) {
  setHeaderToken(token)
}

axios.defaults.withCredentials = true

if (process.env.VUE_APP_MODE == "production") {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_PRODUCTION
} else if (process.env.VUE_APP_MODE == "stagging") {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_STAGGING
} else {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_LOCAL
}

import MoneyInput from '@/components/MoneyInput'
import GlobalFunctions from './plugins/global-functions';

Vue.use(GlobalFunctions)

Vue.component('money-input', MoneyInput)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('LL')
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')